import React from 'react';

interface FilterButtonProps {
  isOpen: boolean;
  closeSideMenu: () => void;
  openSideMenu: () => void;
  numFilters?: number;
}

export default function FilterButton({
  isOpen,
  closeSideMenu,
  openSideMenu,
  numFilters = 0,
}: FilterButtonProps) {
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <button
      className={`relative flex flex-shrink-0 items-center border-gray-250 border-solid p-4 text-sm font-semibold leading-none whitespace-nowrap rounded-xl focus:outline-none focus:ring-blue-600 focus:ring-2 
        ${
          isOpen
            ? 'bg-gray-800 text-white hover:bg-gray-900'
            : 'bg-white text-gray-800 hover:bg-gray-900 hover:text-white'
        }`}
      onClick={isOpen ? closeSideMenu : openSideMenu}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{ borderWidth: '1px' }}
    >
      <div className="flex gap-2 items-center self-stretch my-auto">
        <img
          loading="lazy"
          src={`${__ASSETS_COMMON_IMAGES_URL__}/icons/filter-group.svg`}
          className={`object-contain shrink-0 self-stretch my-auto w-4 aspect-square`}
          alt="filter button"
          style={
            isOpen || isHovered
              ? {
                  filter: 'invert(1)',
                }
              : {}
          }
        />
        <span className="self-stretch my-auto">Filters</span>
      </div>
      {!!numFilters && (
        <div className="absolute rounded-full right-0 top-0 bg-blue-200 w-5 h-5 m-auto flex items-center justify-center -right-2 -top-2 text-sm text-blue-900">
          {numFilters}
        </div>
      )}
    </button>
  );
}
